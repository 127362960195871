import { staticPostals } from 'core/constants/static.constants'
import { useT } from 'hooks/translation.hook'
import React, { useEffect, useState } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'

const postals = staticPostals

const SmartLocalInput = ({ postalCode, value, handleChange, touched, error, props }) => {
  const [locality, setLocality] = useState(value)
  const t = useT()

  useEffect(() => {
    if (postalCode) {
      // Find the corresponding locality for the entered postal code
      const selectedPostal = postals.find((postal) => postal.postalCode === postalCode)
      if (selectedPostal) {
        setLocality(selectedPostal.local)
      } else {
        setLocality('')
      }
    }
  }, [postalCode])

  // Filter localities based on the selected postal code
  const filteredLocalities = postals
    .filter((postal) => postal.postalCode === postalCode)
    .map((postal) => postal.local)

  const handleLocalChange = (e) => {
    setLocality(e.target.value)
    handleChange(e)
  }

  return (
    <Form.Group {...props}>
      {console.log('postalCode', postalCode)}
      {console.log('filteredLocalities', filteredLocalities)}
      <Form.Label>{t('local')}</Form.Label>
      <Form.Control
        as="select"
        name="local"
        value={locality}
        onChange={handleLocalChange}
        isValid={touched && !error}
        isInvalid={touched && !!error}
        disabled={!postalCode || filteredLocalities.length === 0}
      >
        {filteredLocalities.length === 0 ? (
          <option value="">{t('hint_smart_local')}</option>
        ) : (
          filteredLocalities.map((loc, index) => (
            <option key={index} value={loc}>
              {loc}
            </option>
          ))
        )}
      </Form.Control>
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>
  )
}

export default SmartLocalInput

SmartLocalInput.propTypes = {
  props: PropTypes.any,
  postalCode: PropTypes.string,
  value: PropTypes.string,
  touched: PropTypes.bool,
  error: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
}
