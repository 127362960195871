import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'reactstrap'

// import images
import services from '../../assets/images/illustrator/services.svg'
import { useT } from 'hooks/translation.hook'

const WelcomeSection = () => {
  const t = useT()
  // Allow to scroll to the request form
  const scrollToElement = () => {
    // Find the target element by its ID
    const element = document.getElementById('request')
    if (element) {
      // Calculate the desired scroll position
      const offset = element.getBoundingClientRect().top - 74
      // Scroll to the calculated position
      window.scrollBy({ top: offset, behavior: 'smooth' })

      // Scroll to the target element
      // element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <React.Fragment>
      <section className="bg-half-170 d-table w-100" id="home">
        <Container>
          <Row className="align-items-center">
            <Col lg={7} md={7}>
              <div className="title-heading mt-4">
                <h1 className="heading mb-3">{t('welcome_title')}</h1>
                {/* <p className="para-desc text-muted">
                  Launch your campaign and benefit from our expertise on designing and managing
                  conversion centered bootstrap v5 html page.
                </p> */}
                <div className="mt-4 pt-2">
                  <Link onClick={scrollToElement} className="btn btn-primary m-1">
                    {t('begin')}
                  </Link>{' '}
                </div>
              </div>
            </Col>

            <Col lg={5} md={5} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
              <img src={services} alt="" />
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default WelcomeSection
