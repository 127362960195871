import { defaultCoin } from 'core/constants/config.constants'

function capitalizeFirstLetterOfEachWord(str) {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

function toMoney(string) {
  return defaultCoin + ' ' + string
}

export { capitalizeFirstLetterOfEachWord, capitalizeFirstLetter, toMoney }
