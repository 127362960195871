import React, { useEffect, useRef, useState } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import CountUp from 'react-countup'

import PropTypes from 'prop-types'

const CounterBox = ({ counters }) => {
  const [isVisible, setIsVisible] = useState(false)
  const componentRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Se o componente começar a ficar visível na tela
        if (entry.isIntersecting) {
          setIsVisible(true)
          // Desliga o observador após o componente ficar visível uma vez
          observer.unobserve(componentRef.current)
        }
      },
      { threshold: 0.5 }, // Define o threshold para 50%
    )

    // Começa a observar o componente
    observer.observe(componentRef.current)

    // Retorna uma função de limpeza para desligar o observador quando o componente for desmontado
    return () => {
      observer.unobserve(componentRef.current) // eslint-disable-line react-hooks/exhaustive-deps
    }
  }, [])

  return (
    <React.Fragment>
      <Container className="mt-100 mt-60">
        <Row className="justify-content-center" id="counter" ref={componentRef}>
          {isVisible &&
            counters.map((counter, key) => (
              <Col md={4} key={key} className="mt-4 pt-2">
                <div className="counter-box text-center px-lg-4">
                  <h2 className="mb-0 text-primary display-4">
                    <span className="counter-value">
                      <CountUp end={counter.end} duration={8} />
                    </span>
                    {counter.postFix}
                  </h2>
                  <h5 className="counter-head">{counter.title}</h5>
                  <p className="text-muted mb-0">{counter.desc}</p>
                </div>
              </Col>
            ))}
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default CounterBox

CounterBox.propTypes = {
  counters: PropTypes.any,
}
