import { debugLog } from 'core/utils'
import { useState } from 'react'
import * as formik from 'formik'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { toastDefaultDuration } from 'core/constants/config.constants'
export * as validations from 'core/utils/form_validations.utils'
export const Yup = yup

export const useFormValidations = (validations) => {
  const { Formik } = formik
  const [isLoading, setLoading] = useState(false)
  const validationSchema = yup.object(validations)

  // from a object fill the inputs of formState or initialize with empty values
  const InitInputs = (values) => {
    if (values === undefined || values === null) {
      values = validations
    } else if (Object.entries(values).length !== Object.entries(validations).length) {
      values = { ...validations, ...values }
    }

    let initialData = {}
    Object.entries(values).forEach(([key, value]) => {
      if (typeof value === 'object') {
        initialData[key] = ''
        return
      }
      initialData[key] = value
    })

    return initialData
  }

  const handleSubmission = async (formData, submitFunction) => {
    // const id = toast.loading(t('submitting_form'))
    const id = toast.loading('submitting_form')
    try {
      debugLog('Submitting a form', formData)
      setLoading(true)
      // Call the submit function provided by the component
      await submitFunction(formData)
      setLoading(false)
      toast.update(id, {
        // render: t('success_submission'),
        render: 'success_submission',
        type: 'success',
        isLoading: false,
        autoClose: toastDefaultDuration,
      })
    } catch (error) {
      debugLog('Error on handleSubmission', error)
      setLoading(false)
      toast.update(id, {
        // render: t('error_submission'),
        render: 'error_submission',
        type: 'error',
        isLoading: false,
        autoClose: toastDefaultDuration,
      })
      setLoading(false)
      // TODO - Handle errors from Server
      // const newErrors = {}
      // error.inner.forEach((err) => {
      //   newErrors[err.path] = err.message
      // })
      // setErrors(newErrors)
    }
  }

  return {
    FormValidator: Formik,
    validationSchema,
    InitInputs,
    isLoading,
    setLoading,
    handleSubmission,
  }
}
