export const staticServiceCounters = [
  {
    title: 'À Temps',
    end: 97,
    postFix: '%',
    desc: "Nous parvenons à conclure presque toutes les commandes dans le délai imparti et nous continuons encore à travailler pour que 'presque tous' devienne 'tous'!",
  },
  {
    title: 'Prise en Charge',
    end: 48,
    postFix: 'h',
    desc: "Notre mission est de résoudre votre demande dans un délai maximum de 48 heures, vous offrant ainsi tranquillité d'esprit et efficacité.",
  },
]

export const staticPostals = [
  { postalCode: '1870', local: 'Monthey' },
  { postalCode: '1871', local: 'Choëx' },
  { postalCode: '1872', local: 'Troistorrents' },
  { postalCode: '1860', local: 'Aigle' },
  { postalCode: '1868', local: 'Collombey' },
  { postalCode: '1869', local: 'Massongex' },
  { postalCode: '1890', local: 'St-Maurice' },
  { postalCode: '1891', local: 'Vérossaz' },
  { postalCode: '1892', local: 'Lavey-Village' },
  { postalCode: '1893', local: 'Muraz (Collombey)' },
  { postalCode: '1873', local: "Val-d'Illiez" },
  { postalCode: '1874', local: 'Champéry' },
  { postalCode: '1897', local: 'Le Bouveret' },
  { postalCode: '1898', local: 'St-Gingolph' },
  { postalCode: '1865', local: 'Les Diablerets' },
  { postalCode: '1866', local: 'Ovronnaz' },
  { postalCode: '1875', local: 'Morgins' },
  { postalCode: '1896', local: 'Vouvry' },
  { postalCode: '1895', local: 'Vionnaz' },
  { postalCode: '1899', local: 'Torgon' },
  { postalCode: '1876', local: 'Montreux' },
  { postalCode: '1820', local: 'Montreux' },
  { postalCode: '1820', local: 'Territet' },
  { postalCode: '1820', local: 'Veytaux' },
  { postalCode: '1820', local: 'Clarens' },
  { postalCode: '1820', local: 'Glion' },
  { postalCode: '1820', local: 'Brent' },
]
