// React Basic and Bootstrap
import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'reactstrap'

//Import Counter Box
import CounterBox from './CounterBox'

// Import images
import saas from 'assets/images/saas/classic01.png'
import { staticServiceCounters } from 'core/constants/static.constants'

const Counter = () => {
  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row className="align-items-center">
            <Col md={6} className="order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title me-lg-5">
                <h4 className="title mb-4">
                  Soyez en phase avec les services que vous avez demandés.
                </h4>
                <p className="text-muted">
                  Après avoir passé la commande, nos services feront leur possible pour trouver un
                  professionnel aussi rapidement que possible et répondant à vos besoins.
                </p>
                <Link to="#" className="btn btn-outline-primary">
                  Voyons voir ? <i className="uil uil-angle-right-b"></i>
                </Link>
              </div>
            </Col>

            <Col md={6} className="order-1 order-md-2">
              <img src={saas} className="img-fluid" alt="" />
            </Col>
          </Row>
        </Container>

        <CounterBox counters={staticServiceCounters} />
      </section>
    </React.Fragment>
  )
}

export default Counter
