import * as NumberUtils from './number.utils'
import * as StringUtils from './string.utils'
import * as DateUtils from './date.utils'

// allow to print console logs only on dev mode
export function debugLog(message, ...optionalParams) {
  if (process.env.APP_ENV !== 'production') {
    console.log(...message, ...optionalParams)
  }
}
export function debugErrorLog(message, ...optionalParams) {
  if (process.env.APP_ENV !== 'production') {
    console.error(...message, ...optionalParams)
  }
}

export { NumberUtils, StringUtils, DateUtils }
