import * as yup from 'yup'

export const inputEqualsTo = (other, msg) =>
  yup
    .string()
    .oneOf([yup.ref(other)], msg)
    .required(msg)

export const requiredCheckBox = () => yup.bool().required().oneOf([true])

export const inputStrongPassword = (msgRequired, msg) =>
  yup
    .string()
    .test('strong-password', msg, (value) => {
      return (
        value &&
        value.length >= 8 &&
        /[a-z]/.test(value) &&
        /[A-Z]/.test(value) &&
        /[0-9]/.test(value) &&
        /[!@#$%^&*(),.?":{}|<>]/.test(value)
      )
    })
    .required(msgRequired)

export const inputEmail = (msgRequired, msg) => yup.string().email(msg).required(msgRequired)
export const inputRequired = (msg) => yup.string().required(msg)
