import React from 'react'

import Home from 'views/home'

const routes = [
  //Home
  { path: '/', component: <Home /> },
]

export default routes
