import React from 'react'
import { Link } from 'react-router-dom'
import { Container } from 'reactstrap'

//Import images
import logodark from 'assets/images/logo/logo-black.webp'
import logolight from 'assets/images/logo/logo-white.webp'

// Your component definition
const TopMenu = () => {
  return (
    <header id="topnav" className="defaultscroll sticky nav-sticky">
      <Container>
        <div>
          {/* logo */}
          <Link className="logo" to="/">
            <img src={logodark} height="24" className="logo-light-mode" alt="" />
            <img src={logolight} height="24" className="logo-dark-mode" alt="" />
          </Link>
        </div>

        {/* <ul className="navigation-menu nav-dark" id="top-menu">
              <li><Link to="/index" className="sub-menu-item">Home</Link></li>
            </ul> */}
      </Container>
    </header>
  )
}

export default TopMenu
