import React, { useEffect } from 'react'
import { UncontrolledAlert } from 'reactstrap'
import { Link } from 'react-router-dom'

const CookiesPopup = () => {
  useEffect(() => {
    const handleClick = () => {
      const cookiePopup = document.getElementById('cookie-popup')
      if (cookiePopup) {
        const button = cookiePopup.querySelector('button')
        if (button) {
          button.click()
        }
      }
    }

    const timeoutId = setTimeout(handleClick, 5000) // Run handleClick after 5 seconds

    return () => {
      clearTimeout(timeoutId) // Clear the timeout if the component unmounts before 5 seconds
    }
  }, []) // Empty dependency array ensures the effect runs only once on component mount

  return (
    <React.Fragment>
      <UncontrolledAlert id="cookie-popup" className="card cookie-popup shadow rounded py-3 px-4">
        <p className="text-muted mb-0 fs-6">
          This website uses cookies to provide you with a great user experience. By using it, you
          accept our{' '}
          <Link to="/terms" target="_blank" rel="noopener noreferrer" className="text-success h6">
            use of cookies
          </Link>
        </p>
      </UncontrolledAlert>
    </React.Fragment>
  )
}

export default CookiesPopup
