// React Basic and Bootstrap
import React from 'react'

import WelcomeSection from './WelcomeSection'
import Counter from './Counter'
import RequestForm from 'components/Shared/forms/RequestForm'
import CookiesPopup from 'components/Layout/cookies_popup'

const Home = () => {
  return (
    <React.Fragment>
      {/* Welcome */}
      <WelcomeSection />

      <RequestForm />

      {/* Counter */}
      <Counter />

      {/* <Footer6 /> */}
      <CookiesPopup />
    </React.Fragment>
  )
}

export default Home
