import { StringUtils } from 'core/utils'
import { useTranslation } from 'react-i18next'

export function useTLowerCase(string) {
  const { t } = useTranslation()
  if (string != null) return t(string)

  return (string) => t(string)
}

// to avoid all make const { t } = useTranslation()
export function useT(string, options) {
  const { t } = useTranslation()
  if (string != null) return StringUtils.capitalizeFirstLetter(t(string, options))

  return (string, options) => StringUtils.capitalizeFirstLetter(t(string, options))
}

export function useTCapitalizeWords(string, options) {
  const { t } = useTranslation()

  if (string != null) return StringUtils.capitalizeFirstLetterOfEachWord(t(string, options))

  return (string, options) => StringUtils.capitalizeFirstLetterOfEachWord(t(string, options))
}
