import { useFormValidations, validations } from 'hooks/form.hook'
import { useT } from 'hooks/translation.hook'
import React from 'react'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import SmartLocalInput from './parts/SmartLocalInput'

const RequestForm = () => {
  const t = useT()
  const schema = {
    postalCode: validations.inputRequired(t('field_required')),
    local: validations.inputRequired(t('field_required')),
    address: validations.inputRequired(t('field_required')),
    surname: validations.inputRequired(t('field_required')),
    name: validations.inputRequired(t('field_required')),
    birthday: validations.inputRequired(t('field_required')),
    phoneNumber: validations.inputRequired(t('field_required')),
    email: validations.inputEmail(t('field_required'), t('invalid_email')),
    description: validations.inputRequired(t('field_required')),
    terms: validations.requiredCheckBox(),
  }

  const { FormValidator, validationSchema, InitInputs, handleSubmission } =
    useFormValidations(schema)

  const submitFunction = (data) => {
    console.log('formData', data)
  }

  return (
    <section>
      <Row id="request" className="p-2 bg-light justify-content-center">
        <Col md={10} lg={6}>
          <div className="section-title">
            <h4 className="title mb-2 text-center">Votre Prise en Charge</h4>
            <p className="text-muted text-center">
              Deixe-nos encontrar o melhor serviço por você. Basta preencher o formulário abaixo.
            </p>
          </div>
          <FormValidator
            validationSchema={validationSchema}
            onSubmit={(data) => handleSubmission(data, submitFunction)}
            initialValues={InitInputs({ userType: 'referent' })}
          >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row className="mb-2">
                  <Form.Group as={Col} className="d-flex justify-content-center align-items-center">
                    <Form.Label className="me-4"> {t('im')} </Form.Label>
                    <Form.Check
                      className="me-2"
                      type="radio"
                      name="userType"
                      id="referent"
                      label={t('user_type_reference')}
                      value="referent"
                      checked={values.userType === 'referent'}
                      onChange={handleChange}
                    />
                    <Form.Check
                      className="me-2"
                      type="radio"
                      name="userType"
                      id="relative"
                      label={t('user_type_relative')}
                      value="relative"
                      checked={values.userType === 'relative'}
                      onChange={handleChange}
                    />
                    <Form.Check
                      className="me-2"
                      type="radio"
                      name="userType"
                      id="patient"
                      label={t('user_type_patient')}
                      value="patient"
                      checked={values.userType === 'patient'}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group as={Col} md="6" controlId="postalCode" className="mb-2 mb-md-0">
                    <Form.Label>{t('postalCode')}</Form.Label>
                    <Form.Control
                      type="text"
                      name="postalCode"
                      placeholder={t('hint_postalCode')}
                      value={values.postalCode}
                      onChange={handleChange}
                      isValid={touched.postalCode && !errors.postalCode}
                      isInvalid={touched.postalCode && !!errors.postalCode}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.postalCode}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="local">
                    <SmartLocalInput
                      postalCode={values.postalCode}
                      value={values.local}
                      handleChange={handleChange}
                      touched={touched.local}
                      error={errors.local}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group as={Col} controlId="address">
                    <Form.Label>{t('address')}</Form.Label>
                    <Form.Control
                      type="text"
                      name="address"
                      placeholder={t('hint_address')}
                      value={values.address}
                      onChange={handleChange}
                      isValid={touched.address && !errors.address}
                      isInvalid={touched.address && !!errors.address}
                    />
                    <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group as={Col} md="6" controlId="surname" className="mb-2 mb-md-0">
                    <Form.Label>{t('surname')}</Form.Label>
                    <Form.Control
                      type="text"
                      name="surname"
                      placeholder={t('hint_surname')}
                      value={values.surname}
                      onChange={handleChange}
                      isValid={touched.surname && !errors.surname}
                      isInvalid={touched.surname && !!errors.surname}
                    />
                    <Form.Control.Feedback type="invalid">{errors.surname}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="name">
                    <Form.Label>{t('name')}</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder={t('hint_name')}
                      value={values.name}
                      onChange={handleChange}
                      isValid={touched.name && !errors.name}
                      isInvalid={touched.name && !!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group as={Col} md="6" controlId="birthday" className="mb-2 mb-md-0">
                    <Form.Label>{t('birthday')}</Form.Label>
                    <Form.Control
                      type="date"
                      name="birthday"
                      max={new Date().toISOString().split('T')[0]}
                      value={values.birthday}
                      onChange={handleChange}
                      isValid={touched.birthday && !errors.birthday}
                      isInvalid={touched.birthday && !!errors.birthday}
                    />
                    <Form.Control.Feedback type="invalid">{errors.birthday}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="sex">
                    <Form.Label>{t('sexe')}</Form.Label>
                    <Form.Control
                      as="select"
                      name="sex"
                      value={values.sex}
                      onChange={handleChange}
                      isValid={touched.sex && !errors.sex}
                      isInvalid={touched.sex && !!errors.sex}
                    >
                      <option>{t('female')}</option>
                      <option>{t('male')}</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{errors.sex}</Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group as={Col} md="4" controlId="phoneNumber" className="mb-2 mb-md-0">
                    <Form.Label>{t('phoneNumber')}</Form.Label>
                    <Form.Control
                      type="tel"
                      name="phoneNumber"
                      placeholder={t('hint_phoneNumber')}
                      value={values.phoneNumber}
                      onChange={handleChange}
                      isValid={touched.phoneNumber && !errors.phoneNumber}
                      isInvalid={touched.phoneNumber && !!errors.phoneNumber}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phoneNumber}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="8" controlId="email">
                    <Form.Label>{t('email')}</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder={t('hint_email')}
                      value={values.email}
                      onChange={handleChange}
                      isValid={touched.email && !errors.email}
                      isInvalid={touched.email && !!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group as={Col} controlId="description">
                    <Form.Label>{t('description')}</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="description"
                      placeholder={t('hint_description')}
                      value={values.description}
                      onChange={handleChange}
                      isValid={touched.description && !errors.description}
                      isInvalid={touched.description && !!errors.description}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.description}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group as={Col} controlId="terms">
                    <Form.Check
                      label={
                        <span>
                          {t('i_agree')}{' '}
                          <Link to="/terms" target="_blank" rel="noopener noreferrer">
                            {t('terms_and_conditions').toLowerCase()}
                          </Link>
                        </span>
                      }
                      name="terms"
                      value={values.terms}
                      onChange={handleChange}
                      isValid={touched.terms && !errors.terms}
                      isInvalid={touched.terms && !!errors.terms}
                    />
                  </Form.Group>
                </Row>
                <Button variant="primary" type="submit">
                  {t('submit')}
                </Button>
              </Form>
            )}
          </FormValidator>
        </Col>
      </Row>
    </section>
  )
}

export default RequestForm
