import React, { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

// Import Css
import './assets/css/materialdesignicons.min.css'
import './Apps.scss'
import 'i18n'

// Include Routes
import routes from './routes/routes'
import withRouter from './core/withRouter'
import Scaffold from 'components/Layout/Scaffold'

const App = () => {
  const LoaderComponent = () => (
    <div id="">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  )

  return (
    <React.Fragment>
      <Suspense fallback={<LoaderComponent />}>
        <Routes>
          {routes.map((route, idx) => (
            <Route
              path={route.path}
              element={<Scaffold hasDarkTopBar={route.isTopbarDark}> {route.component}</Scaffold>}
              key={idx}
            />
          ))}
        </Routes>
      </Suspense>
    </React.Fragment>
  )
}

export default withRouter(App)
